<template>
  <div class="points-practice">

    <div class="form">
        <ion-button size="large" @click="updateShotCount(1)">1 Point</ion-button>
        <ion-button size="large" @click="updateShotCount(3)">3 Points</ion-button>

        <input type="number" :value="data.points" readonly />
    </div>
  </div>
</template>

<script>

import { IonButton } from '@ionic/vue'; 

export default {
    name: 'Points',

    components: {
        IonButton,
    },

    props: {
        data: {
            type: Object,
            default: null,
        },

        readOnly: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {};
    },

    computed: {

    },

    watch: {
    },

    created() {
        this.updateShotCount(0);
    },

    methods: {
        updateShotCount(delta) {
            const newData = { ...this.data };

            newData.points = newData.points || 0;

            newData.points += delta;

            this.$emit('update:data', newData); // emit point update feed back in with sync

            this.$emit('shotadd', {});
        }
    },
}
</script>

<style lang="scss" scoped>
.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 3rem;

    ion-button {
        width: 200px;
    }

    input {
        border: 1px solid #d4d4d4;
        font-size: 3rem;
        width: 200px;
        text-align: center;
        border-radius: 0.4rem;
        margin: 1rem 0;
    }
}
</style>