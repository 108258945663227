<template>
<ion-page>
    <template v-if="gameSessionId">
      <ion-header :translucent="true">
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-menu-button color="primary"></ion-menu-button>
          </ion-buttons>
          <ion-title>{{ currentGame?.title }}</ion-title>
        </ion-toolbar>
      </ion-header>
      
      <ion-content :fullscreen="true">
        <div id="container" v-if="gameSession && currentGame">
          <component
            :is="currentGame.configs.component"
            :shots="gameSession.shots"
            v-model:data="gameSession.data"
            :read-only="isComplete"
            @shotadd="onShotAdd"
            @shotremove="onShotRemove"
          />

          <div class="configs">
            <div v-if="gameSession.config.club" class="config">
              <h3>{{ gameSession.config.club }}</h3>
              <div>Club</div>
            </div>
            <div v-if="gameSession.config.distance" class="config">
              <h3>{{ gameSession.config.distance }}yds</h3>
              <div>Distance</div>
            </div>
            <div v-if="currentGame.stats.shotCount" class="config">
              <h3>{{ gameSession.shots.length }}</h3>
              <div>Shots</div>
            </div>
            <div v-if="currentGame.stats.xDev" class="config">
              <h3>{{ gameSession.data.xDev }}</h3>
              <div>xDEV</div>
            </div>
            <div v-if="currentGame.stats.yDev" class="config">
              <h3>{{ gameSession.data.yDev }}</h3>
              <div>yDEV</div>
            </div>
            <div v-if="currentGame.stats.points" class="config">
              <h3>{{ gameSession.data.points }}</h3>
              <div>Pts</div>
            </div>
          </div>
          <ion-button v-if="!isComplete" expand="full" size="large" @click="onCompleteGameSession">
              Complete
          </ion-button>

          <ion-button v-if="isComplete" color="danger" expand="full" size="large" @click="onDeleteGameSession">
              Delete
          </ion-button>
        </div>
      </ion-content>
    </template>
  </ion-page>
</template>

<script>
import {
  IonContent, 
  IonHeader, 
  IonMenuButton, 
  IonButtons,
  IonPage, 
  IonTitle, 
  IonToolbar,
  IonButton,
} from '@ionic/vue';
import TargetPractice from '@/components/games/target/TargetPractice';
import ClubFace from '@/components/games/target/ClubFace';
import Count from '@/components/games/putting/Count';
import Points from '@/components/games/putting/Points';

export default {
  name: 'GameSession',
  inject: ['global'],
  components: {
    IonContent,
    IonHeader,
    IonButtons,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,

    TargetPractice,
    ClubFace,
    Count,
    Points,
  },

  props: {
    isPlanGameSession: {
      type: Boolean,
      default: false,
    },
  },

  setup() {

    return {
    };
  },

  computed: {
    gameSessionId() {
      return this.$route.params.id;
    },
    gameSession() {
      return this.global.gameSessions.getById(this.gameSessionId);
    },
    currentGame() {
      return this.global.games.getById(this.gameSession?.gameId);
    },
    isComplete() {
      return !!this.gameSession.completedAt;
    },
  },

  created() {
  },

  data() {
    return {
      
    };
  },

  methods: {
    onShotAdd(shot) {
      this.global.gameSessions.addShot(this.gameSession.id, shot);
    },

    onShotRemove() {
      this.global.gameSessions.removeShot(this.gameSession.id);
    },

    onCompleteGameSession() {
      this.global.gameSessions.completeGameSession(this.gameSessionId);

      if (this.isPlanGameSession) {
        this.$router.push({
          name: 'PlanSessionDetails',
          params: {
            planSessionId: this.$route.params.planSessionId
          }
        });
        
      } else {
        this.$router.push({
          name: 'GameSessionsList',
        });
      }
    },

    onDeleteGameSession() {
      this.global.gameSessions.removeGameSession(this.gameSessionId);

      this.$router.push({
        name: 'GameSessionsList',
      });
    },
  },
}
</script>

<style scoped lang="scss">
#container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0;

  > div:first-child {
    flex: 1;
  }

  .configs {
    display: flex;
    margin: 1.5rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-wrap: wrap;

    .config {
      margin: 0 1.5rem;

      > div {
        text-transform: uppercase;
        font-weight: bold;
        color: #666;
        font-size: 1.1rem;
      }
    }
  }
}
</style>