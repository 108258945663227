<template>
  <div class="target-practice">
    <div class="target" @click="onTargetClick($event)">
        <img src="/assets/images/clubface-iron.png" />
        <div
            class="shot"
            v-for="(shot, index) in shots"
            :key="index"
            :style="`top: calc(${shot.y}% - 4px); left: calc(${shot.x}% - 4px);`"
        >
        </div>

        <Ellipse v-if="(data.ellipse && !readOnly) || (data.ellipse && showStats === 2 && readOnly)" :ellipse="data.ellipse" />

        <div v-if="showStats === 1" class="overlay">
            <div><span>{{ quadrants.T }}%</span></div>
            <div><span>{{ quadrants.C }}%</span></div>
            <div><span>{{ quadrants.H }}%</span></div>
        </div>
    </div>
  </div>
</template>

<script>
import Ellipse from '@/components/games/target/Ellipse';

export default {
    name: 'ClubFace',

    components: {
        Ellipse,
    },

    props: {
        shots: {
            type: Array,
            default: () =>[],
        },

        data: {
            type: Object,
            default: null
        },

        readOnly: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            showStats: 0, // 0 = hidden, 1 = quadrants, 2 = ellipse
        };
    },

    computed: {
        quadrants() {
            const stats = {};
            ['T', 'C', 'H'].forEach((q) => {
                const val = (this.shots.filter((s) => s.quadrant === q).length / this.shots.length) * 100;

                stats[q] = Math.round(val);
            });

            return stats;
        },
    },

    methods: {
        onTargetClick(e) {
            if (this.readOnly) {
                let showStats = this.showStats + 1;
                this.showStats = showStats > 2 ? 0 : showStats;
                return;
            }

            const rect = e.target.getBoundingClientRect();

            // x/y values as absolute pixel positions
            const xAbs = e.clientX - rect.left; // x position within the element.
            const yAbs = e.clientY - rect.top;  // y position within the element.

            // x/y values for percentage positions
            const x = (xAbs / rect.width) * 100;
            const y = (yAbs / rect.height) * 100;

            // x/y value if the target had a central axis
            const centreX = xAbs - (rect.width / 2);
            const centreY = (rect.height / 2) - yAbs;
            
            this.$emit('shotadd', {
                x,
                y,
                xAbs,
                yAbs,
                centreX,
                centreY,
                width: rect.width,
                height: rect.height,
                quadrant: this.getQuadrant(xAbs),
            });
        },

        /**
         * Calculates the quadrant based on a 45deg rotated cross split. E.g.
         * | T | C | H |
         * Returns T, C, H
         */
        getQuadrant(x) {
            let quadrant = '';

            if (x <= 90) {
                quadrant = 'T';
            } else if (x > 90 && x <= 180) {
                quadrant = 'C';
            } else {
                quadrant = 'H';
            }

            return quadrant;
        },
    },
}
</script>

<style lang="scss" scoped>
.target {
    position: relative;
    width: 300px;
    height: 300px;
    margin: 0 auto;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    img {
        position: absolute;
        width: 300px;
        height: 300px;
    }
}

.shot {
    position: absolute;
    z-index: 9;
    width: 8px;
    height: 8px;
    background: red;
    border-radius: 50%;
    border: 1px solid white;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 50%;
    z-index: 9;

    > div {
        width: 90px;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        font-weight: bold;
        border-right: 1px solid #000;

        &:last-child {
            border-right: none;
        }
    }
}
</style>