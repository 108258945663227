<template>
    <svg ref="svg" width="300" height="300">
        <g>
            <ellipse
                :rx="Math.abs((ellipse.xExtent[0] + ellipse.rx) - ellipse.xExtent[0])"
                :ry="Math.abs((ellipse.yExtent[0] + ellipse.ry) - ellipse.yExtent[0])"
                :transform="`translate(${ellipse.cx}, ${ellipse.cy}) rotate(${ellipse.orient})`"
                :style="`fill: ${fill}`"
            />
        </g>
    </svg>
</template>

<script>
export default {
    props: {
        ellipse: {
            type: Object,
            default: null,
        },

        fill: {
            type: String,
            default: 'rgba(180, 211, 246, 0.5)',
        },
    }
}
</script>

<style lang="scss" scoped>
svg {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  pointer-events: none;

  ellipse {
    stroke: rgba(180, 211, 246, 1);
    fill: rgba(180, 211, 246, 0.5);
  }
}
</style>