<template>
  <div class="count-practice">
    <div class="form">
        <ion-button size="large" @click="increment">+</ion-button>
        <input type="number" v-model="shotCount" />
        <ion-button size="large" @click="decrement">-</ion-button>
    </div>
  </div>
</template>

<script>

import { IonButton } from '@ionic/vue'; 

export default {
    name: 'Count',

    components: {
        IonButton,
    },

    props: {
        shots: {
            type: Array,
            default: () =>[],
        },

        data: {
            type: Object,
            default: null
        },

        readOnly: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            shotCount: 0,
        };
    },

    computed: {

    },

    watch: {
        shots: {
            immediate: true,
            deep: true,
            handler(shots) {
                this.shotCount = Array.isArray(shots) ? shots.length : 0;
            },
        },
    },

    methods: {
        increment() {
            this.updateShotCount(1);
        },
        decrement() {
            this.updateShotCount(-1);
        },
        updateShotCount(delta) {
            if (delta === 1) {
                this.$emit('shotadd', {});
            } else {
                this.$emit('shotremove', {});
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 3rem;

    ion-button {
        width: 200px;
    }

    input {
        border: 1px solid #d4d4d4;
        font-size: 3rem;
        width: 200px;
        text-align: center;
        border-radius: 0.4rem;
        margin: 1rem 0;
    }
}
</style>